import React from 'react'
import PropTypes from 'prop-types'
import iphonePanoPoster from '../../../../../images/scope/iphone-pano-poster.webp'
import iphonePanoVideo from '../../../../../images/scope/iphone-pano.mp4'
import { Filenames } from '../../filenames'
import { IrisImg, Mp4Video } from '../../../../Shared'

const Panoramas = ({ images, mediaType }) => {
  const content = {
    intro: {
      title: 'Supports all leading panorama formats',
      body:
        'Create panoramas in V-Ray, OctaneRender, Lumion, Autodesk A360, Corona Renderer and more. Then, view in virtual reality using your mobile device.',
      videoImg: iphonePanoPoster,
      videoSrc: iphonePanoVideo,
    },
    cubemaps: [
      {
        subtitle: 'Chaos Group V-Ray VR Panorama',
        body:
          'Pictured above is a 12x1 cubic panorama rendered in V-Ray for 3ds Max.',
        filename: Filenames.vrayPano,
        imgAlt: 'V-Ray Panorama',
        linkUrl:
          'https://labs.chaosgroup.com/index.php/portfolio/guide-to-virtual-reality-2/',
      },
      {
        subtitle: 'OTOY Octane VR Panorama',
        body: 'Pictured above is a 12x1 cubic panorama rendered in Octane.',
        filename: Filenames.octanePano,
        imgAlt: 'Octane Panorama',
        linkUrl: 'https://home.otoy.com/vr-competition/',
      },
      {
        subtitle: 'Lumion VR Panorama',
        body: 'Pictured above is a 12x1 cubic panorama rendered in Lumion.',
        filename: Filenames.lumionPano,
        imgAlt: 'Lumion Panorama',
        linkUrl: 'https://lumion3d.com/what-s-new.html',
      },
    ],
  }

  const sizeCodes = {
    tiny: 'sm',
    extraSmall: 'sm',
    small: 'sm',
    medium: 'md',
    large: 'lg',
    infinity: 'lg',
  }

  const size = sizeCodes[mediaType]
  const playerClass = mediaType === 'large' ? 'player-lg' : ''

  /* eslint-disable jsx-a11y/media-has-caption */
  return (
    <section className="panoramas">
      <div className={`container content ${size}`}>
        <div className="intro col-lg-12 col-md-12 col-sm-12 col-xs-12 center">
          <div className="col-lg-6 col-md-6">
            <h1>{content.intro.title}</h1>
            <p className="desc">{content.intro.body}</p>
          </div>
          <div className={`col-lg-6 col-md-6 ${playerClass}`}>
            <Mp4Video
              poster={content.intro.videoImg}
              video={content.intro.videoSrc}
            />
          </div>
        </div>
        {content.cubemaps.map((item, i) => (
          <div
            key={i}
            className="cubemap-container col-lg-12 col-md-12 col-sm-12 col-xs-12 center"
          >
            <IrisImg
              file={images[item.filename]}
              className={`cubemap ${size}`}
              alt={item.imgAlt}
            />
            <p className={`subtitle ${size}`}>{item.subtitle}</p>
            <p>{item.body}</p>
            <a href={item.linkUrl} target="_blank" rel="noopener noreferrer">
              <button className={`learn-more-button ${size}`}>
                Learn More
              </button>
            </a>
          </div>
        ))}
      </div>
    </section>
  )
}

Panoramas.propTypes = {
  mediaType: PropTypes.string.isRequired,
  images: PropTypes.shape().isRequired,
}

export default Panoramas
