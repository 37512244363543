export const isLarge = width => width >= 992

export default {
  tiny: 320,
  extraSmall: 480,
  small: 810,
  medium: 992,
  large: 1200,
  infinity: Infinity,
}
