import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from '../components/Shared'
import Scope, { helmetProps } from '../components/Products/Scope'
import { arrayToObject } from '../utils/image'

export const query = graphql`
  {
    splashImage: file(
      relativeDirectory: { eq: "scope/fluid" }
      name: { eq: "scope-splash" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    scopeLogo: file(
      relativeDirectory: { eq: "scope/fluid" }
      name: { eq: "scope-logo-white" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 295) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    panos: allFile(filter: { relativeDirectory: { eq: "scope/panos" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }

    vrayLogo: file(
      relativeDirectory: { eq: "scope/more-info" }
      name: { eq: "vray-logo" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    octaneLogo: file(
      relativeDirectory: { eq: "scope/more-info" }
      name: { eq: "octane-logo" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 126) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    lumionLogo: file(
      relativeDirectory: { eq: "scope/more-info" }
      name: { eq: "lumion-logo" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 126) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    autodeskLogo: file(
      relativeDirectory: { eq: "scope/more-info" }
      name: { eq: "autodesk-360-logo" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 126) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mobileFeature1: file(
      relativeDirectory: { eq: "scope/more-info" }
      name: { eq: "mobile-features1" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 46) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    mobileFeature2: file(
      relativeDirectory: { eq: "scope/more-info" }
      name: { eq: "mobile-features2" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 46) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    libPano: file(
      relativeDirectory: { eq: "scope/library" }
      name: { eq: "lib-pano" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 640) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    mobileFlow1: file(
      relativeDirectory: { eq: "scope/library" }
      name: { eq: "mobile-flow1" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 60) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    mobileFlow2: file(
      relativeDirectory: { eq: "scope/library" }
      name: { eq: "mobile-flow2" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 60) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    mobileFlow3: file(
      relativeDirectory: { eq: "scope/library" }
      name: { eq: "mobile-flow3" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 60) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

export default React.memo(({ data }) => (
  <>
    <Helmet {...helmetProps} />
    <Scope
      splashImages={arrayToObject([data.splashImage, data.scopeLogo])}
      panoramaImages={arrayToObject(data.panos.edges.map(e => e.node))}
      moreInfoImages={arrayToObject([
        data.vrayLogo,
        data.octaneLogo,
        data.lumionLogo,
        data.autodeskLogo,
        data.mobileFeature1,
        data.mobileFeature2,
      ])}
      libraryImages={arrayToObject([
        data.libPano,
        data.mobileFlow1,
        data.mobileFlow2,
        data.mobileFlow3,
      ])}
    />
  </>
))
