import React from 'react'
import { Link } from 'gatsby'
import { IrisImg } from '../../../../Shared'
import './SplashContainer.scss'

const SplashContainer = ({ mediaType, images }) => {
  const sizeCodes = {
    tiny: 'xs',
    extraSmall: 'xs',
    small: 'sm',
    medium: 'md',
    large: 'lg',
    infinity: 'in',
  }

  return (
    <div className={`splash-main scope ${sizeCodes[mediaType]}`}>
      <div className="content">
        <div className={`intro center ${sizeCodes[mediaType]}`}>
          <IrisImg file={images['scope-logo-white']} alt="scope-logo" />
          <div className={`title ${sizeCodes[mediaType]}`}>
            <p>360 Panoramas Anytime, Anywhere</p>
          </div>
          <div className="button-container">
            <Link to="/trial?product=scope">
              <button className="iris-button scope-download-button">
                Free Trial
              </button>
            </Link>
            <a
              href="https://irisvr.com/checkout?plan=scope&cycle=annual"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="iris-cta-button scope-plan-button">
                Buy Now
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SplashContainer
