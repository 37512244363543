import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const GoogleCardboard = ({ mediaType }) => {
  const content = {
    title: 'Works With Google Cardboard',
    body: [
      'Affordable and easy to use.',
      'Google Cardboard works with any smartphone.',
      'IrisVR recommends I Am Cardboard. Use code IRISVR at checkout for savings.',
    ],
  }

  const sizeCodes = {
    tiny: 'xs',
    extraSmall: 'xs',
    small: 'sm',
    medium: 'md',
    large: 'lg',
    infinity: 'in',
  }

  const sizeClass = sizeCodes[mediaType]

  return (
    <section className={`mobile ${sizeClass}`}>
      <div className="container content">
        <div className="textbox col-lg-6 col-md-6 col-sm-6 col-xs-12 center">
          <div
            className={`intro col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 col-xs-12 ${sizeClass}`}
          >
            <h1 className="header-large">{content.title}</h1>
            <div className="detail center">
              {content.body.map((text, i) => (
                <p key={i}>{text}</p>
              ))}
            </div>
            <div className="button-container">
              <Link to="/downloads">
                <button className="mobile-download-button">
                  Download For Free
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 background" />
      </div>
    </section>
  )
}

GoogleCardboard.propTypes = {
  mediaType: PropTypes.string.isRequired,
}

export default GoogleCardboard
