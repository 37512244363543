import { getWindowLocationOrigin } from '../../../utils/window'
import { generateMetaTags } from '../../../utils/helmet'

const title = 'Scope - 360 Panoramas in Virtual Reality (VR) | IrisVR'
const description =
  'Mobile design review for virtual reality (VR). Works with Google Daydream/Cardboard headsets to view renderings + 360 panoramas from Lumion, V-Ray, and more.'
const url = `${getWindowLocationOrigin()}/scope`
const image = 'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Scope.jpg'
const imageWidth = 1200
const imageHeight = 630

export default generateMetaTags({
  title,
  description,
  image,
  url,
  imageWidth,
  imageHeight,
})
