import React from 'react'
import PropTypes from 'prop-types'
import { IrisImg } from '../../../../Shared'
import { Filenames } from '../../filenames'

const MoreInfo = ({ images, isLargerThanMedium }) => {
  const content = {
    intro: {
      title: 'Mobile First',
      body:
        'Scope works with both iOS and Android devices in a Google Cardboard, or with a dedicated Samsung phone in Gear VR. Our software tracks your head movement and allows you to look around your space.',
    },
    logos: [
      { name: 'vray', filename: Filenames.vrayLogo, alt: 'V-Ray' },
      { name: 'octane', filename: Filenames.octaneLogo, alt: 'Octane' },
      { name: 'lumion', filename: Filenames.lumionLogo, alt: 'Lumion' },
      { name: 'autodesk', filename: Filenames.autodeskLogo, alt: 'Autodesk' },
    ],
    features: [
      {
        subtitle: 'Easy Interface',
        body: 'All commands and functions can be completed with one tap.',
        filename: Filenames.mobileFeature1,
      },
      {
        subtitle: 'Optimized for Comfort',
        body:
          'IrisVR maximizes comfort in mobile VR by running at 60fps, the smoothest and most responsive experience that smartphones can achieve today.',
        filename: Filenames.mobileFeature2,
      },
    ],
  }

  const sizeClass = isLargerThanMedium ? '' : 'sm'
  const featureClass = isLargerThanMedium ? 'lg' : ''

  return (
    <section className={`more-info ${sizeClass}`}>
      <div className="container content">
        <div className="heading col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="intro col-lg-4 col-md-4">
            <h1>{content.intro.title}</h1>
            <p>{content.intro.body}</p>
          </div>
        </div>
        <div className="logos col-lg-7 col-md-7">
          {content.logos.map(logo => (
            <IrisImg
              key={logo.name}
              file={images[logo.filename]}
              className={`${logo.name} logo`}
              alt={logo.alt}
            />
          ))}
        </div>
        <div className="features col-lg-12 col-md-12 col-sm-12 col-xs-12">
          {content.features.map((feature, i) => (
            <div
              key={i}
              className={`feature col-sm-6 col-md-3 col-lg-4 col-xs-8 ${featureClass}`}
            >
              <IrisImg file={images[feature.filename]} alt={feature.subtitle} />
              <p className="subtitle">{feature.subtitle}</p>
              <p>{feature.body}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

MoreInfo.propTypes = {
  isLargerThanMedium: PropTypes.bool.isRequired,
  images: PropTypes.shape().isRequired,
}

export default MoreInfo
