export const Filenames = {
  scopeSplash: 'scope-splash',
  scopeLogo: 'scope-logo-white',
  autodeskLogo: 'autodesk-360-logo',
  libPano: 'lib-pano',
  lumionLogo: 'lumion-logo',
  lumionPano: 'lumion-pano',
  mobileFeature1: 'mobile-features1',
  mobileFeature2: 'mobile-features2',
  mobileFlow1: 'mobile-flow1',
  mobileFlow2: 'mobile-flow2',
  mobileFlow3: 'mobile-flow3',
  octaneLogo: 'octane-logo',
  octanePano: 'octane-pano',
  scopeModel: 'scope-model',
  vrayLogo: 'vray-logo',
  vrayPano: 'vray-pano',
}
